import React from 'react';
import S from '../../../elements/Start';
import cat from '../../../images/cat.svg';
import kittens from '../../../images/kittens.svg';
import gallery from '../../../images/gallery.svg';
import contact from '../../../images/contact.svg';

export const Links = () => {
  return (
    <S.LinksContainer>
      <S.LinkWrapper>
        <S.LinkItem to="/neva-masquerade">
          <S.LinkIcon src={cat} alt="Katt" />
          <S.LinkText>Om Kattrasen</S.LinkText>
        </S.LinkItem>
      </S.LinkWrapper>
      <S.LinkWrapper>
        <S.LinkItem to="/kattungarna">
          <S.LinkIcon src={kittens} alt="Kattungar" />
          <S.LinkText>Kattungarna</S.LinkText>
        </S.LinkItem>
      </S.LinkWrapper>
      <S.LinkWrapper>
        <S.LinkItem to="/galleri">
          <S.LinkIcon src={gallery} alt="Galleri" />
          <S.LinkText>Galleri</S.LinkText>
        </S.LinkItem>
      </S.LinkWrapper>
      <S.LinkWrapper>
        <S.LinkItem to="/kontakt">
          <S.LinkIcon src={contact} alt="Kontakt" />
          <S.LinkText>Kontakt</S.LinkText>
        </S.LinkItem>
      </S.LinkWrapper>
    </S.LinksContainer>
  );
};
