import React from 'react';
import S from '../../elements/Kittens';
import { Footer, NavBar } from '../../components/Common';
import { Kitten } from '../../components/Kittens';
import hero from '../../images/hero.jpg';

const Kittens = () => {
  return (
    <S.PageContainer>
      <S.HeaderContainer>
        <NavBar />
      </S.HeaderContainer>
      <S.ContentContainer>
        <Kitten
          image={hero}
          title="Mr Bingo"
          text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus tenetur vero consequuntur accusantium eum maxime laborum ipsa temporibus? Ullam quae laudantium iure ipsam alias labore quasi optio. Modi et velit corrupti neque rerum odit ab pariatur omnis tempora reprehenderit perferendis voluptas unde praesentium voluptatem ad, voluptate natus necessitatibus sit officia.          "
        />
        <Kitten
          image={hero}
          title="Mr Bingo"
          text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus tenetur vero consequuntur accusantium eum maxime laborum ipsa temporibus? Ullam quae laudantium iure ipsam alias labore quasi optio. Modi et velit corrupti neque rerum odit ab pariatur omnis tempora reprehenderit perferendis voluptas unde praesentium voluptatem ad, voluptate natus necessitatibus sit officia.          "
        />
      </S.ContentContainer>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.PageContainer>
  );
};
export default Kittens;
