import React from 'react';
import S from '../../elements/Start';
import { NavBar, Footer } from '../../components/Common';
import { Hero, Links } from '../../components/Start';

const Start = () => {
  return (
    <S.PageContainer>
      <S.HeaderContainer>
        <NavBar />
      </S.HeaderContainer>
      <S.HeroContainer>
        <Hero />
      </S.HeroContainer>
      <S.NavContainer>
        <Links />
      </S.NavContainer>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.PageContainer>
  );
};

export default Start;
