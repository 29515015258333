import React from 'react';
import { useLocation } from 'react-router-dom';
import S from '../../../elements/Common';
import logo from '../../../images/logo.svg';

export const NavBar = () => {
  const { pathname } = useLocation();
  return (
    <S.NavBarContainer>
      <S.Logo src={logo} alt="Katt" />
      <S.HeaderTitle>SÖDERSLÄTTSKATTEN</S.HeaderTitle>
      {pathname !== '/' && <S.ButtonLink to="/">Tillbaka</S.ButtonLink>}
    </S.NavBarContainer>
  );
};
