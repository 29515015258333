import React from 'react';
import S from '../../../elements/Start';
import hero from '../../../images/hero.jpg';

export const Hero = () => {
  return (
    <S.HeroWrapper>
      <S.HeroLeft>
        <S.HeroTitle>Möt kattrasen Neva Masquerade!</S.HeroTitle>
        <S.HeroButton>Läs Om Rasen</S.HeroButton>
      </S.HeroLeft>
      <S.HeroImage url={hero} />
    </S.HeroWrapper>
  );
};
