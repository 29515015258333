import styled from 'styled-components';

export const FooterText = styled.p`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.8rem;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
