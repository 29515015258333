const theme = {
  colors: {
    primary: '#89cff0',
    secondary: '#fff',
  },
  fonts: {
    logo: "'Open Sans', sans-serif",
    heading: "'Caveat', cursive",
    body: "'Montserrat', sans-serif",
  },
};

export default theme;
