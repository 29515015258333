import styled from 'styled-components';

const PageContainer = styled.section`
  display: grid;
  grid-template-areas:
    'header   header   header   header'
    'content  content  content  content'
    'footer   footer   footer   footer';
  grid-template-rows: 3rem 1fr 3rem;
  row-gap: 1rem;
  min-height: 100vh;
  padding-top: 0.5rem;
`;

const HeaderContainer = styled.header`
  grid-area: header;
`;

const ContentContainer = styled.section`
  grid-area: content;
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 1.5rem;
  padding: 1rem;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
`;

const Title = styled.h2`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-size: clamp(2.8rem, 7vw, 3rem);
  font-family: ${({ theme }) => theme.fonts.heading};
  margin-bottom: 1rem;

  :not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export default {
  PageContainer,
  HeaderContainer,
  ContentContainer,
  FooterContainer,
  Title,
};
