import styled from 'styled-components';

const PageContainer = styled.section`
  display: grid;
  grid-template-areas:
    'header   header   header   header'
    'content  content  content  content'
    'footer   footer   footer   footer';
  grid-template-rows: 3rem 1fr 3rem;
  row-gap: 1rem;
  min-height: 100vh;
  padding-top: 0.5rem;
`;

const HeaderContainer = styled.header`
  grid-area: header;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
`;

const ContactDetailsContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

const DetailWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const Icon = styled.img`
  width: 3rem;
  margin-bottom: 0.5rem;
`;

const ContactFormContainer = styled.form`
  grid-area: content;
  height: fit-content;
  padding-top: 2rem;

  @media only screen and (min-width: 720px) {
    grid-column: 2 / 4;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 1.5rem;
  width: 100%;
`;

const Input = styled.input`
  font-family: ${({ theme }) => theme.fonts.body};
  width: 100%;
  height: 2.5rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 1rem;
  padding: 0 1rem;
  margin-top: 0.5rem;
`;

const TextArea = styled.textarea`
  font-family: ${({ theme }) => theme.fonts.body};
  line-height: 1.2rem;
  width: 100%;
  height: 15rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 1.5rem;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  resize: none;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem 0;
  border: none;
  border-radius: 8px;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

export default {
  PageContainer,
  HeaderContainer,
  FooterContainer,
  DetailWrapper,
  Icon,
  ContactDetailsContainer,
  ContactFormContainer,
  InputWrapper,
  InputLabel,
  Input,
  TextArea,
  Button,
  FormWrapper,
};
