import React from 'react';
import S from '../../elements/NevaMasquerade';
import { Footer, NavBar, Paragraph } from '../../components/Common';

const NevaMasquerde = () => {
  return (
    <S.PageContainer>
      <S.HeaderContainer>
        <NavBar />
      </S.HeaderContainer>
      <S.ContentContainer>
        <S.Title>Neva Masquerade</S.Title>
        <Paragraph>
          Det här är en sann skönhet med sitt ursprung i Ryssland. Den har många
          likheter med Sibirisk katt – ganska naturligt eftersom båda är
          skogskatter. Däremot är Neva Masquerade alltid maskad och har blå ögon
          – även som vuxen.
        </Paragraph>
        <S.Title>Utseende</S.Title>
        <Paragraph>
          Pälsen är lång och tjock och den har en krage runt huvud och nacke.
          Den har även tofsar på öronen och tassarna. Får inte glömma att nämna
          den fluffiga svansen, som även den ger ett stilfullt intryck. Huvudet
          är cirkelformat med rundad panna, medelstora breda öron och stora blå
          intensiva ögon. De s.k. kalla och spetsiga kroppsdelarana är öron,
          svansspets och tassar som ska ha samma färg som ansiktet, den så
          kallade ”masken”. Övrig kropp har en ljusare grundfärg. De olika
          tillåtna färgvarianterna är:
          <br />
          <br />
          Seal-point Har en svart grundfärg som syns på de kalla och spetsiga
          delarna.
          <br />
          Blue-point Har en förtunnad svart grundfärg som därför beskrivs som
          blå.
        </Paragraph>
        <S.Title>Ursprung</S.Title>
        <Paragraph>
          Den är syskonras till Sibirisk katt, men det var inte förrän 2009 som
          den blev godkänd av FIFe (Federation Internationale Feline) som en
          egen ras. Därefter har den blivit allt vanligare i flertalet länder
          och blir allt mer omtyckt. Rasnamnet har sitt ursprung från floden
          Neva i Ryssland eftersom det var där den först...
        </Paragraph>
      </S.ContentContainer>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.PageContainer>
  );
};

export default NevaMasquerde;
