import styled from 'styled-components';

export const AppContainer = styled.main`
  max-width: 1200px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;
`;
