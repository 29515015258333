import { hot } from 'react-hot-loader/root';
import { Route, Switch } from 'react-router-dom';
import Start from './views/Start';
import NevaMasquerade from './views/NevaMasquerade';
import Kittens from './views/Kittens';
import Gallery from './views/Gallery';
import Contact from './views/Contact';
import S from './elements/Common';
import { useRouteScrollTop } from './hooks/useRouteScrollTop';

const App = () => {
  useRouteScrollTop();
  return (
    <S.AppContainer>
      <Switch>
        <Route path="/neva-masquerade">
          <NevaMasquerade />
        </Route>
        <Route path="/kattungarna">
          <Kittens />
        </Route>
        <Route path="/galleri">
          <Gallery />
        </Route>
        <Route path="/kontakt">
          <Contact />
        </Route>
        <Route path="/">
          <Start />
        </Route>
      </Switch>
    </S.AppContainer>
  );
};

export default hot(App);
