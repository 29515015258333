import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.section`
  display: grid;
  grid-template-areas:
    'header header header header'
    'hero   hero   hero   hero'
    'link   link   link   link'
    'footer footer footer footer';
  grid-template-rows: 3rem 30rem 1fr 3rem;
  row-gap: 1rem;
  min-height: 100vh;
  padding-top: 0.5rem;
`;

const HeaderContainer = styled.header`
  grid-area: header;
`;

const HeroContainer = styled.section`
  grid-area: hero;
`;

const NavContainer = styled.nav`
  grid-area: link;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 2rem 2rem 0 0;

  @media only screen and (min-width: 600px) {
    border-radius: 2rem 0 0 2rem;
  }
`;

const HeroTitle = styled.h2`
  max-width: 19rem;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 2.8rem;
  font-family: ${({ theme }) => theme.fonts.heading};

  @media only screen and (min-width: 600px) {
    max-width: 25rem;
    font-size: clamp(2.8rem, 7vw, 4rem);
  }
`;

const HeroButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 0.9rem;
  max-width: 15rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  padding: 0.5rem 2.5rem;

  @media only screen and (min-width: 600px) {
    max-width: 20rem;
    width: clamp(90%, 5vw, 100%);
    font-size: 1.2rem;
  }
`;

const HeroImage = styled.div`
  background-image: url(${({ url }) => url});
  object-fit: cover;
  background-size: cover;
  background-position: top;
  flex: 50%;
  border-radius: 0 0 2rem 2rem;

  @media only screen and (min-width: 600px) {
    border-radius: 0 2rem 2rem 0;
  }
`;

const LinksContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  gap: 1rem;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 600px) {
    padding-top: 2rem;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
`;

const LinkWrapper = styled.li`
  position: relative;
  padding-top: 100%;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 1.5rem;
`;

const LinkItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

const LinkIcon = styled.img`
  width: 4rem;

  @media only screen and (min-width: 600px) {
    width: clamp(2rem, 8vw, 7rem);
  }
`;

const LinkText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.body};

  @media only screen and (min-width: 600px) {
    font-size: clamp(1rem, 2.5vw, 2rem);
  }
`;

export default {
  PageContainer,
  HeaderContainer,
  HeroContainer,
  NavContainer,
  FooterContainer,
  HeroWrapper,
  HeroLeft,
  HeroTitle,
  HeroButton,
  HeroImage,
  LinksContainer,
  LinkWrapper,
  LinkItem,
  LinkIcon,
  LinkText,
};
