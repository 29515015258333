import React from 'react';
import S from '../../../elements/Common';

export const Footer = () => (
  <S.FooterText>
    Hemsidan är gjord av&nbsp;
    <a
      href="https://www.joelpedersen.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Joel Pedersen
    </a>
  </S.FooterText>
);
