import styled from 'styled-components';

const PageContainer = styled.section`
  display: grid;
  grid-template-areas:
    'header   header   header   header'
    'content  content  content  content'
    'footer   footer   footer   footer';
  grid-template-rows: 3rem 1fr 3rem;
  row-gap: 1rem;
  min-height: 100vh;
  padding-top: 0.5rem;
`;

const HeaderContainer = styled.header`
  grid-area: header;
`;

const ContentContainer = styled.section`
  grid-area: content;
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 1.5rem;
  padding: 1rem;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
`;

const KittenItemContainer = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 3rem;
  width: 100%;

  :not(:last-child) {
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr;

    :nth-child(odd) section {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    :nth-child(odd) img {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }
`;

const KittenItemImage = styled.img`
  border-radius: 2rem;
`;

const KittenItemWrapper = styled.section``;

const KittenItemTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: clamp(2.8rem, 7vw, 3rem);
  margin-bottom: 1rem;
`;

export default {
  PageContainer,
  HeaderContainer,
  ContentContainer,
  FooterContainer,
  KittenItemContainer,
  KittenItemImage,
  KittenItemWrapper,
  KittenItemTitle,
};
