import * as App from './App/App';
import * as Header from './Header/Header';
import * as Paragraph from './Paragraph/Paragraph';
import * as Footer from './Footer/Footer';

export default {
  ...App,
  ...Header,
  ...Paragraph,
  ...Footer,
};
