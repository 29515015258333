import React from 'react';
import S from '../../elements/Contact';
import { Footer, NavBar, Paragraph } from '../../components/Common';
import contact from '../../images/contact.svg';
import email from '../../images/email.svg';

const Contact = () => {
  return (
    <S.PageContainer>
      <S.HeaderContainer>
        <NavBar />
      </S.HeaderContainer>
      <S.ContactFormContainer>
        <S.FormWrapper>
          <S.InputWrapper>
            <S.InputLabel>Namn</S.InputLabel>
            <S.Input />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.InputLabel>E-post</S.InputLabel>
            <S.Input />
          </S.InputWrapper>
          <S.InputLabel>Meddelande</S.InputLabel>
          <S.TextArea />
          <S.Button>Skicka</S.Button>
        </S.FormWrapper>
        <S.ContactDetailsContainer>
          <S.DetailWrapper>
            <S.Icon src={contact} />
            <Paragraph>070-764 45 25</Paragraph>
          </S.DetailWrapper>
          <S.DetailWrapper>
            <S.Icon src={email} />
            <Paragraph>mack-14@hotmail.com</Paragraph>
          </S.DetailWrapper>
        </S.ContactDetailsContainer>
      </S.ContactFormContainer>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.PageContainer>
  );
};

export default Contact;
