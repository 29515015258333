import styled from 'styled-components';

const PageContainer = styled.section`
  display: grid;
  grid-template-areas:
    'header   header   header   header'
    'content  content  content  content'
    'footer   footer   footer   footer';
  grid-template-rows: 3rem 1fr 3rem;
  row-gap: 1rem;
  min-height: 100vh;
  padding-top: 0.5rem;
`;

const HeaderContainer = styled.header`
  grid-area: header;
`;

const ContentContainer = styled.section`
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-auto-rows: min-content;
  gap: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 1.5rem;
  padding: 1rem;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
`;

const ImageContainer = styled.figure`
  position: relative;
  padding-top: 100%;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: fit-content;
  object-fit: cover;
  border-radius: 2rem;
`;

export default {
  PageContainer,
  HeaderContainer,
  ContentContainer,
  FooterContainer,
  ImageContainer,
  Image,
};
