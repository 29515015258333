import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Logo = styled.img`
  height: clamp(80%, 4vw, 100%);
  margin-right: 0.5rem;
`;

export const HeaderTitle = styled.h1`
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  font-family: ${({ theme }) => theme.fonts.logo};
  color: ${({ theme }) => theme.colors.primary};
`;

export const ButtonLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.body};
  padding: 0.5rem;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  margin-left: auto;
`;
