import React from 'react';
import S from '../../../elements/Kittens';
import { Paragraph } from '../../Common';

export const Kitten = ({ image, title, text }) => {
  return (
    <S.KittenItemContainer>
      <S.KittenItemWrapper>
        <S.KittenItemTitle>{title}</S.KittenItemTitle>
        <Paragraph>{text}</Paragraph>
      </S.KittenItemWrapper>
      <S.KittenItemImage src={image} />
    </S.KittenItemContainer>
  );
};
