import React from 'react';
import S from '../../elements/Gallery';
import { Footer, NavBar } from '../../components/Common';
import hero from '../../images/hero.jpg';
import cat1 from '../../images/cat1.jpg';
import cat2 from '../../images/cat2.jpg';
import cat3 from '../../images/cat3.jpg';
import cat4 from '../../images/cat4.jpg';
import cat5 from '../../images/cat5.jpg';

const Gallery = () => {
  return (
    <S.PageContainer>
      <S.HeaderContainer>
        <NavBar />
      </S.HeaderContainer>
      <S.ContentContainer>
        <S.ImageContainer>
          <S.Image src={hero} />
        </S.ImageContainer>
        <S.ImageContainer>
          <S.Image src={cat1} />
        </S.ImageContainer>
        <S.ImageContainer>
          <S.Image src={cat2} />
        </S.ImageContainer>
        <S.ImageContainer>
          <S.Image src={cat3} />
        </S.ImageContainer>
        <S.ImageContainer>
          <S.Image src={cat4} />
        </S.ImageContainer>
        <S.ImageContainer>
          <S.Image src={cat5} />
        </S.ImageContainer>
      </S.ContentContainer>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.PageContainer>
  );
};

export default Gallery;
